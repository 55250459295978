export const DATE_FORMAT = 'Y-m-d'

export const domains = {
  payiano: 'https://payiano.com',
  postman: 'https://postman.com/payiano',
  github: 'https://github.com/payiano',
  swagger: 'https://swagger.io'
}

export const MAX_ALLOWED_WEBHOOK_URLS_PER_COMPANY = 10

export const PREFERRED_RESPONSE_DATETIME_HEADER =
  'X-Preferred-Response-Datetime-Format'

export const WEBHOOK_EVENT_ID_HEADER = 'X-Payiano-Webhook-Event-Id'

export const WEBHOOK_EVENT_ATTEMPT_ID_HEADER =
  'X-Payiano-Webhook-Event-Attempt-Id'

export const WEBHOOK_SIGNATURE_HEADER = 'X-Payiano-Webhook-Signature'
